import React, { useEffect, useState } from 'react';
import './App.css';
import CountUp from 'react-countup';
import { Typography, Stack, Button, Card, CardContent, Grid, Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
const dayjs = require('dayjs')
var relativeTime = require('dayjs/plugin/relativeTime')

var numeral = require('numeral');

dayjs.extend(relativeTime)

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function FormDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [initialAmount, setInitialAmount] = useState(props.initialAmount);
  const [percentYield, setPercentYield] = useState(props.percentYield);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = () => {
    setOpen(false);
    props.onUpdateValues(Number.parseFloat(initialAmount), Number.parseFloat(percentYield));
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Update Values
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Values</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set the initial amount and percent yield per year. Interested earned will be reset.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="initialAmount"
            label="Initial Amount"
            fullWidth
            variant="standard"
            value={initialAmount}
            onChange={(e) => setInitialAmount(e.target.value)}
          />
          <TextField
            margin="dense"
            id="percentYield"
            label="Percent Yield"
            fullWidth
            variant="standard"
            value={percentYield}
            onChange={(e) => setPercentYield(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSave}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const Earnings = (props: any) => {
  const { title, amount } = props;
  return <Grid item xs={4}>
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h3" component="div">
          Earnings Per {title}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {numeral(amount).format('$0,0.00000000')}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
}

function App() {

  let lastTimeString = localStorage.getItem('startTime');
  if (!lastTimeString) {
    lastTimeString = new Date().toISOString();
    localStorage.setItem('startTime', lastTimeString);
  }

  const lastTime = new Date(lastTimeString);
  const currentTime = new Date();
  const elapsed = (currentTime.getTime() - lastTime.getTime());

  const [counter, setCounter] = useState(elapsed / 1000);
  const [initialAmount, setInitialAmount] = useState(465000);
  const [percentYield, setPercentYield] = useState(3.95);

  var increment = (percentYield / 100) / (356 * 24 * 60 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [increment]);

  const onUpdateValues = (initialValue: number, percentYield: number) => {
    setInitialAmount(initialValue);
    setPercentYield(percentYield);
    setCounter(0);
    lastTimeString = new Date().toISOString();
    localStorage.setItem('startTime', lastTimeString);
  }

  const formatter = React.useCallback((e: number) => {
    return numeral(e).format('$0,0.000000000000');
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: '200px' }}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Stack spacing={1}>
          <Typography variant="h1" gutterBottom >
            <CountUp end={initialAmount * ((counter * increment) + 1)} decimals={10} preserveValue duration={1} start={100} formattingFn={formatter} />
          </Typography>
          <Typography variant="h2">
            Yield: {percentYield.toFixed(2)}%
          </Typography>
          <Typography variant="h2">
            Initial Investment: {numeral(initialAmount).format('$0,0.00')}
          </Typography>
          <Typography variant="h4">
            Interest Earned since {dayjs(lastTime).fromNow()}: ${(initialAmount * (counter * increment)).toFixed(8)}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Earnings title="Year" amount={initialAmount * (percentYield / 100)} />
              <Earnings title="Month" amount={initialAmount * (percentYield / 100 / 12)} />
              <Earnings title="Day" amount={initialAmount * (percentYield / 100 / 365)} />
              <Earnings title="Hour" amount={initialAmount * (percentYield / 100 / 365 / 24)} />
              <Earnings title="Minute" amount={initialAmount * (percentYield / 100 / 365 / 24 / 60)} />
              <Earnings title="Second" amount={initialAmount * (percentYield / 100 / 365 / 24 / 60 / 60)} />
            </Grid>
          </Box>
          <Stack direction='row' spacing={1}>
            <FormDialog onUpdateValues={onUpdateValues} />
          </Stack>
        </Stack>
      </ThemeProvider>
    </div>

  );
}

export default App;
